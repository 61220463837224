body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  width: 100%;
}

.landing {
  position: relative;
  background: url(/static/media/showcase.4b31330b.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin-top: -24px;
  margin-bottom: -50px;
}

.landing-inner {
  padding-top: 80px;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-form {
  opacity: 0.9;
}

.latest-profiles-img {
  width: 40px;
  height: 40px;
}

.form-control::-webkit-input-placeholder {
  color: #bbb !important;
}

.form-control::-ms-input-placeholder {
  color: #bbb !important;
}

.form-control::placeholder {
  color: #bbb !important;
}

.table {
  border-spacing: 0px;
  width: 100%;
}

.table-responsive-md .table td .m-icons{display: none;}

.cursor-default{cursor:default !important;}

.table tr.site-row-active{ background: #ffffff;}
.table tr.site-row-not-active{ background: #f5f5f5;}
.table tr.site-row-not-active td a{ color: #cccccc;}
.table tr.site-row-not-active td a:hover{ color: #2D56B3;}

/* Extra Small Devices Styles Starts */
@media (max-width: 767px) {

}

/* Extremely Small Devices Styles Starts */
@media (max-width: 478px) {
  .table-responsive-md .table td, .table th{ font-size: 12px; padding: .30rem;}  
  .table-responsive-md .table td .btn{ font-size: 12px; padding: .20rem .20rem;}  
  .table-responsive-md .table td .d-icons{display: none;}
  .table-responsive-md .table td .m-icons{display: block; font-size: 18px;}
  .table td .badge{ font-size: 11px;}
  .container {    
    padding-right: 8px;
    padding-left: 5px;
  }
  .card-header {    
    padding: .50rem;
  }   

  .card-body {    
    padding: .50rem;
  }   

  .card-body div{ padding-bottom: 8px; }

  .card-body h3{ font-size: 1rem; margin-bottom: .2rem; font-weight: 700;}
  .card-body h2{ font-size: 1rem; margin-bottom: .2rem; font-weight: 700;}
}
